import { NavigationMenu } from '../model/navigation-menu.model';

export const getPrivileges = (): NavigationMenu[] => [
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.DASHBOARD:Dashboard`,
        icon: 'dashboard',
        link: '',
        privileges: ['INTERACTIVE_USER'],
    },
    {
        visible: false,
        link: 'admin/organization',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/details',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/settings',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/merchant-id',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/phone-numbers',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/addresses',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/users',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/edit-user',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/edit-address',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: false,
        link: 'admin/organization/create-user',
        modulesPaths: ['admin', 'admin/organization'],
        privileges: ['ROLE_ORG_ADMIN'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.VULNERABILITY_SCANNING.TITLE:Vulnerability Scanning`,
        icon: 'network_check',
        link: 'scan',
        childMenus: [
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.VULNERABILITY_SCANNING.SETUP_SCAN:Setup Scan`,
                link: 'scan/setup/search',
                modulesPaths: ['scan', 'scan/setup'],
                privileges: ['ROLE_SCAN_USER'],
            },
            {
                visible: false,
                text: 'Scan History',
                link: 'scan/history',
                privileges: ['ROLE_SCAN_USER', 'SCAN_INSTANCE_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.VULNERABILITY_SCANNING.SCAN_HISTORY:Scan History`,
                link: 'scan/history/search',
                privileges: ['ROLE_SCAN_USER', 'SCAN_INSTANCE_READ'],
            },
            {
                visible: false,
                text: 'Scan History Results',
                link: 'scan/history/:id/:module',
                privileges: ['ROLE_SCAN_USER', 'FINDING_READ'],
            },
            {
                visible: false,
                text: 'Scan History Results - Dispute History',
                link: 'scan/history/:id/dispute-history/:disputeId',
                privileges: ['ROLE_SCAN_USER', 'FINDING_READ'],
            },
            {
                visible: false,
                text: 'Scan History Results - Finding Details',
                link: 'scan/history/:id/finding-details/:findingId',
                privileges: ['ROLE_SCAN_USER', 'FINDING_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.VULNERABILITY_SCANNING.SCANNER_APPLIANCES:Scanner Appliances`,
                link: 'scan/scanner/appliances/search',
                privileges: ['ROLE_IVS_SCAN_USER'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.VULNERABILITY_SCANNING.SUPPRESSION_RULES: Suppression Rules`,
                link: 'scan/suppression-rules/search',
                privileges: ['IVS_SCAN', 'EVS_SCAN'],
                modulesPaths: ['scan', 'scan/suppression-rules'],
            },
            {
                visible: false,
                link: 'scan/suppression-rules/edit-rule/:id',
                privileges: ['IVS_SCAN', 'EVS_SCAN'],
                modulesPaths: ['scan', 'scan/suppression-rules'],
            },
            {
                visible: false,
                link: 'scan/suppression-rules/edit-rule',
                privileges: ['IVS_SCAN', 'EVS_SCAN'],
                modulesPaths: ['scan', 'scan/suppression-rules'],
            },
        ],
        privileges: [
            'ROLE_SCAN_USER',
            'SCAN_INSTANCE_READ',
            'ROLE_IVS_SCAN_USER',
            'IVS_SCAN',
            'ROLE_EVS_SCAN_USER',
            'EVS_SCAN',
        ],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.TITLE:Assessment`,
        icon: 'assignment',
        childMenus: [
            {
                visible: false,
                link: 'assessment/dashboard',
                privileges: ['ASSESSMENT_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.BASIC_DASHBOARD:Dashboard`,
                link: 'assessment/dashboard/basic',
                privileges: ['ASSESSMENT_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.ADVANCE_DASHBOARD:Dashboard`,
                link: 'assessment/dashboard/advanced',
                privileges: ['ASSESSMENT_DASHBOARD_ADVANCED_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.ASSESSMENTS:Assessments`,
                link: 'assessment/management/search',
                privileges: ['ASSESSMENT_READ'],
            },
            {
                visible: false,
                text: 'Assessment Management',
                link: 'assessment',
                privileges: ['ASSESSMENT_READ'],
                childMenus: [
                    {
                        visible: false,
                        text: 'Assessment Management',
                        link: 'assessment/management',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Assessment Management',
                        link: 'assessment/management/:module',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Assessment Management',
                        link: 'assessment/management/:module/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Add milestone',
                        link: 'assessment/management/milestone/:id/form',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Edit milestone',
                        link: 'assessment/management/milestone/:id/form/:milestoneId',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Clients Provide Task Access',
                        link: 'assessment/management/clients/provide-access/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Clients Revoke Task Access',
                        link: 'assessment/management/clients/revoke-access/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Clients Assign Tasks',
                        link: 'assessment/management/clients/assign-tasks/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Clients Unassign Tasks',
                        link: 'assessment/management/clients/unassign-tasks/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Assessors Provide Task Access',
                        link: 'assessment/management/assessors/provide-access/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Assessors Revoke Task Access',
                        link: 'assessment/management/assessors/revoke-access/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Form Component',
                        link: 'assessment/management/components/form/:id',
                        privileges: ['ASSESSMENT_READ'],
                    },
                    {
                        visible: false,
                        text: 'Form View Component',
                        link: 'assessment/management/components/view/:id/:name',
                        privileges: ['ASSESSMENT_READ'],
                    },
                ],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.TASKS:Tasks`,
                link: 'assessment/tasks/search',
                privileges: ['TASK_READ'],
            },
            {
                visible: false,
                link: 'assessment/controls',
                privileges: ['ROLE_CONTROL_ADMIN', 'CONTROL_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.CONTROLS:Controls`,
                link: 'assessment/controls/search',
                privileges: ['ROLE_CONTROL_ADMIN'],
            },
            {
                visible: false,
                text: 'Control Form',
                link: 'assessment/controls/form',
                privileges: ['CONTROL_READ'],
            },
            {
                visible: false,
                text: 'Control Form',
                link: 'assessment/controls/form/:module/:id',
                privileges: ['CONTROL_READ'],
            },
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.REFERENCE_TASKS:Reference Tasks`,
                link: 'assessment/reference-tasks',
                privileges: ['ROLE_REFERENCE_TASK_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.REFERENCE_TASKS:Reference Tasks`,
                link: 'assessment/reference-tasks/search',
                privileges: ['ROLE_REFERENCE_TASK_ADMIN'],
            },
            {
                visible: false,
                text: 'Reference Task Form',
                link: 'assessment/reference-tasks/form',
                privileges: ['REFERENCE_TASK_READ'],
            },
            {
                visible: false,
                text: 'Reference Task Form',
                link: 'assessment/reference-tasks/form/:module/:id',
                privileges: ['REFERENCE_TASK_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.FRAMEWORKS:Frameworks`,
                link: 'assessment/frameworks/search',
                privileges: ['ROLE_FRAMEWORK_ADMIN'],
            },
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.FRAMEWORKS:Frameworks`,
                link: 'assessment/frameworks',
                privileges: ['ROLE_FRAMEWORK_ADMIN'],
            },
            {
                visible: false,
                link: 'assessment/frameworks/search',
                privileges: ['ROLE_FRAMEWORK_ADMIN', 'FRAMEWORK_READ'],
            },
            {
                visible: false,
                text: 'Framework Create',
                link: 'assessment/frameworks/create',
                privileges: ['FRAMEWORK_READ'],
            },
            {
                visible: false,
                text: 'Framework Edit',
                link: 'assessment/frameworks/edit',
                privileges: ['FRAMEWORK_READ'],
            },
            {
                visible: false,
                text: 'Framework Edit',
                link: 'assessment/frameworks/edit/:id',
                privileges: ['FRAMEWORK_READ'],
            },
            {
                visible: false,
                text: 'Framework Components',
                link: 'assessment/framework-components',
                privileges: ['CONTROL_UPDATE', 'REFERENCE_TASK_UPDATE'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ASSESSMENT.COMPONENTS:Components`,
                link: 'assessment/framework-components/search',
                privileges: ['CONTROL_UPDATE', 'REFERENCE_TASK_UPDATE'],
            },
            {
                visible: false,
                text: 'Framework Components Form',
                link: 'assessment/framework-components/form',
                privileges: ['CONTROL_READ'],
            },
            {
                visible: false,
                text: 'Framework Components Form',
                link: 'assessment/framework-components/form/:module/:name',
                privileges: ['ASSESSMENT_READ', 'CONTROL_UPDATE'],
            },
        ],
        privileges: [
            'ASSESSMENT_READ',
            'TASK_READ',
            'ROLE_CONTROL_ADMIN',
            'ROLE_REFERENCE_TASK_ADMIN',
            'ROLE_FRAMEWORK_ADMIN',
            'ASSESSMENT_DASHBOARD_ADVANCED_READ',
            'CONTROL_UPDATE',
            'REFERENCE_TASK_UPDATE',
        ],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.MSS:MSS`,
        icon: 'security',
        link: 'mss',
        privileges: ['MSS_DASHBOARD_VIEW'],
        childMenus: [
            {
                visible: false,
                link: 'dashboard',
                privileges: ['MSS_DASHBOARD_VIEW'],
            },
            {
                visible: false,
                link: 'managed/connectivity',
                privileges: ['MSS_MANAGED_CONNECTIVITY_VIEW'],
            },
            {
                visible: false,
                link: 'site/availability',
                privileges: ['MSS_SITE_AVAILABILITY_VIEW'],
            },
            {
                visible: false,
                link: 'site/all',
                privileges: ['MSS_SITE_AVAILABILITY_VIEW'],
            },
            {
                visible: false,
                link: 'site/detail/:index/:id/:siteId',
                privileges: ['MSS_SITE_VIEW'],
            },
            {
                visible: false,
                link: 'ids/report',
                privileges: ['MSS_QBR_REPORT_VIEW'],
            },
            {
                visible: false,
                link: 'mss/ids/report/detail/:signature/:severity',
                privileges: ['MSS_QBR_REPORT_VIEW'],
            },
        ],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.MSS.MSS_SUPPORT:MSS Support`,
        icon: 'security',
        privileges: ['ISP_PROVIDERS_VIEW', 'ROLE_MSS_SUPPORT_USER'],
        childMenus: [
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.MSS.ISP_PROVIDER:ISP Providers List`,
                link: 'mss/support/isp-provider',
                privileges: ['ISP_PROVIDERS_VIEW'],
            },
            {
                visible: false,
                link: 'mss/support/isp-provider/detail',
                privileges: ['ISP_PROVIDERS_VIEW'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.MSS.LTE_ADMIN:LTE Admin`,
                link: 'mss/support/lte-admin',
                privileges: ['LTE_ADMINISTRATION_VIEW'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.MSS.FIREWALL_DEVICES:Firewall Devices`,
                link: 'mss/support/firewall-devices',
                privileges: ['ROLE_MSS_SUPPORT_USER'],
            },
        ],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.MARKETPLACE:Marketplace`,
        icon: 'shopping_cart',
        modulesPaths: ['marketplace'],
        privileges: ['WALLET_READ', 'STORE_READ', 'PAYMENT_GATEWAY_READ'],
        childMenus: [
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.WALLET:Wallet`,
                modulesPaths: ['marketplace/wallet'],
                link: 'marketplace/wallet/view',
                privileges: ['WALLET_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.CATALOG:Catalog`,
                modulesPaths: ['marketplace/catalog'],
                link: 'marketplace/catalog/view',
                privileges: ['STORE_READ'],
            },
            {
                visible: false,
                text: 'Marketplace Checkout',
                modulesPaths: ['marketplace/catalog'],
                link: 'marketplace/catalog/checkout',
                privileges: ['STORE_PURCHASE'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ORDERS:Orders`,
                modulesPaths: ['marketplace/orders'],
                link: 'marketplace/orders/search',
                privileges: ['STORE_READ'],
            },
            {
                visible: false,
                text: 'Order View',
                modulesPaths: ['marketplace/orders'],
                link: 'marketplace/orders/:module/:orderId',
                privileges: ['STORE_READ'],
            },
        ],
    },
    {
        visible: false,
        text: $localize`:@@CORE.LEFT_MENU.FILE_BROWSER:File Browser`,
        icon: 'file_copy',
        link: 'file-browser',
        privileges: ['FILE_LOCKER_UI_READ'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.FILE_BROWSER:File Browser`,
        icon: 'file_copy',
        link: 'file-browser/search',
        privileges: ['FILE_LOCKER_UI_READ'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.FINDINGS.TITLE:Findings`,
        icon: 'find_in_page',
        modulesPaths: ['finding'],
        link: 'finding/search',
        privileges: ['INTERACTIVE_USER', 'FINDING_READ', 'ENDPOINT_FINDING_UI_READ'],
    },
    {
        visible: false,
        text: 'Finding Device',
        link: 'finding/device/:orgId',
        privileges: ['INTERACTIVE_USER', 'FINDING_READ', 'ENDPOINT_FINDING_UI_READ'],
    },
    {
        visible: false,
        text: 'Finding View',
        link: 'finding/view/:module/:id',
        privileges: ['FINDING_READ'],
    },
    {
        visible: false,
        text: 'Finding Form',
        link: 'finding/form/:module/:id',
        privileges: ['FINDING_READ'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.ASSETS:Assets`,
        link: 'asset/search',
        icon: 'flip_to_front',
        modulesPaths: ['asset'],
        privileges: ['ASSET_READ', 'ASSET_MENU'],
        childMenus: [
            {
                visible: false,
                text: 'Asset Form Detail',
                link: 'asset/form/:module/:id',
                privileges: ['ASSET_READ'],
            },
            {
                visible: false,
                text: 'Asset Form',
                link: 'asset/form',
                privileges: ['ASSET_READ'],
            },
            {
                visible: false,
                text: 'Asset View Detail',
                link: 'asset/view/:module',
                privileges: ['ASSET_READ'],
            },
        ],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.EVENTS:Events`,
        icon: 'event',
        link: 'event/search',
        modulesPaths: ['event'],
        privileges: ['INTERACTIVE_USER', 'EVENT_READ'],
        childMenus: [
            {
                visible: false,
                text: 'Event Detail',
                link: 'event/view/:module/:id',
                privileges: ['EVENT_READ'],
            },
        ],
    },
    {
        visible: false,
        text: 'Event Device',
        link: 'event/device/:orgId',
        privileges: ['INTERACTIVE_USER', 'EVENT_READ'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.ENDPOINTS:Endpoints`,
        icon: 'desktop_windows',
        link: 'endpoints/dashboard/devices',
        modulesPaths: ['endpoints'],
        privileges: ['ENDPOINT_READ'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.ADMIN.TITLE:Admin`,
        icon: 'supervisor_account',
        link: 'admin',
        privileges: [
            'ORG_ADMIN',
            'USER_ADMIN',
            'ROLE_ROLE_ADMIN',
            'ROLE_PRIVILEGE_ADMIN',
            'PTAG_CREATE',
            'PTAG_DELETE',
            'PTAG_UPDATE',
            'ROLE_VULN_SCANNER_ADMIN',
            'ROLE_DISPUTE_ADMIN',
            'ROLE_USER_ADMIN_USERS',
            'PRODUCT_FAMILY_INTERNAL',
        ],
        childMenus: [
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.ORGANIZATIONS:Organizations`,
                link: 'admin/org/search',
                modulesPaths: ['admin', 'admin/org'],
                privileges: ['ORG_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.USERS:Users`,
                link: 'admin/user/management/search',
                modulesPaths: ['admin', 'admin/user/management'],
                privileges: ['USER_ADMIN'],
            },
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.USERS:Users`,
                link: 'admin/user/management/search/:orgId',
                modulesPaths: ['admin', 'admin/user/management'],
                privileges: ['USER_READ'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.USERS:Users`,
                link: 'admin/user/search',
                privileges: ['ROLE_USER_ADMIN_USERS'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ORDERS:Orders`,
                link: 'admin/orders',
                privileges: ['ROLE_WALLET_ADMIN'],
            },
            {
                visible: false,
                text: 'Admin Order View',
                link: 'admin/orders/:module/:orderId',
                privileges: ['ROLE_WALLET_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.ROLES_AND_PRIVILEGES:Roles and privileges`,
                link: 'admin/roles-and-privileges',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_ROLE_ADMIN', 'ROLE_PRIVILEGE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/roles/:name',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_ROLE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/roles',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_ROLE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/role-edit/:role',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_ROLE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/role-edit',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_ROLE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/role-hierarchy',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_ROLE_ADMIN'],
                childMenus: [
                    {
                        visible: false,
                        link: 'admin/roles-and-privileges/role-hierarchy/edit/:role',
                        modulesPaths: ['admin', 'roles-and-privileges'],
                        privileges: ['ROLE_ROLE_ADMIN'],
                    },
                ],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/privileges',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_PRIVILEGE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/privilege-edit/:name',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_PRIVILEGE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/roles-and-privileges/privilege-edit',
                modulesPaths: ['admin', 'roles-and-privileges'],
                privileges: ['ROLE_PRIVILEGE_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.PRIVATE_TAGS:Private Tags`,
                link: 'admin/private/tag/search',
                privileges: ['PTAG_CREATE', 'PTAG_DELETE', 'PTAG_UPDATE'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.SCANNING:Scanning`,
                link: 'admin/scanning/scan/search',
                modulesPaths: ['admin', 'admin/scanning'],
                privileges: ['ROLE_VULN_SCANNER_ADMIN'],
            },
            {
                visible: false,
                text: 'Vendor Account',
                link: 'admin/scanning/vendor/account',
                modulesPaths: ['admin', 'admin/scanning'],
                privileges: ['ROLE_VULN_SCANNER_ADMIN'],
            },
            {
                visible: false,
                text: 'Scan counts report',
                link: 'admin/scanning/scan-counts-report',
                modulesPaths: ['admin', 'admin/scanning'],
                privileges: ['ROLE_VULN_SCANNER_ADMIN'],
            },
            {
                visible: false,
                text: 'Public IP exclusion for IVS',
                link: 'admin/scanning/ivs-ip-exclusions',
                modulesPaths: ['admin', 'admin/scanning'],
                privileges: ['ROLE_VULN_SCANNER_ADMIN'],
            },
            {
                visible: false,
                text: 'Scanner Search',
                link: 'admin/scanning/scanner/search',
                modulesPaths: ['admin', 'admin/scanning'],
                privileges: ['ROLE_VULN_SCANNER_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.DISPUTE:Disputes`,
                link: 'admin/disputes/search',
                modulesPaths: ['admin', 'admin/disputes'],
                privileges: ['ROLE_DISPUTE_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/disputes/finding-details/:id',
                modulesPaths: ['admin', 'admin/disputes'],
                privileges: ['ROLE_DISPUTE_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.MSS_PARENT_ACCOUNT:MSS Parent Accounts`,
                link: 'admin/mss/parent-accounts',
                modulesPaths: ['admin', 'admin/mss'],
                privileges: ['MSS_PARENT_ACCOUNT_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/mss',
                modulesPaths: ['admin', 'mss'],
                privileges: ['MSS_PARENT_ACCOUNT_ADMIN'],
                childMenus: [
                    {
                        visible: false,
                        link: 'admin/mss/:module',
                        privileges: ['MSS_PARENT_ACCOUNT_ADMIN'],
                    },
                    {
                        visible: false,
                        link: 'admin/mss/parent-accounts/form',
                        privileges: ['MSS_PARENT_ACCOUNT_ADMIN'],
                    },
                    {
                        visible: false,
                        link: 'admin/mss/parent-accounts/form/:id',
                        privileges: ['MSS_PARENT_ACCOUNT_ADMIN'],
                    },
                    {
                        visible: false,
                        link: 'admin/mss/parent-accounts/view/:id',
                        privileges: ['MSS_PARENT_ACCOUNT_ADMIN'],
                    },
                ],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.PRODUCT_FAMILIES_LINES:Product Families & Lines`,
                link: 'admin/pfpl/list',
                modulesPaths: ['admin', 'admin/pfpl'],
                privileges: ['PRODUCT_FAMILY_INTERNAL', 'PRODUCT_FAMILY_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/pfpl/edit-product/:id',
                modulesPaths: ['admin', 'admin/pfpl'],
                privileges: ['PRODUCT_FAMILY_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/pfpl/edit-product',
                modulesPaths: ['admin', 'admin/pfpl'],
                privileges: ['PRODUCT_FAMILY_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/pfpl/edit-line/:familyId',
                modulesPaths: ['admin', 'admin/pfpl'],
                privileges: ['PRODUCT_FAMILY_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/pfpl/edit-line/:familyId/:id',
                modulesPaths: ['admin', 'admin/pfpl'],
                privileges: ['PRODUCT_FAMILY_ADMIN'],
            },
            {
                visible: false,
                link: 'admin/pfpl/edit-line',
                modulesPaths: ['admin', 'admin/pfpl'],
                privileges: ['PRODUCT_FAMILY_ADMIN'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.ADMIN.PROVISIONING:Provisioning`,
                link: 'admin/provisioning',
                privileges: ['ROLE_MSS_SUPPORT_USER'],
            },
        ],
    },
    {
        visible: false,
        text: $localize`:@@CORE.LEFT_MENU.DEVICES_MANAGEMENT.TITLE:Devices Management`,
        icon: 'rss_feed',
        privileges: [
            'DEVICE_MANAGEMENT_TEMPLATES_READ',
            'DEVICE_MANAGEMENT_SITES_READ',
            'DEVICE_MANAGEMENT_DEVICES_READ',
        ],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.TITLE:Security Operations`,
        icon: 'fingerprint',
        privileges: ['DEMO_USER'],
        link: 'security-operations',
        childMenus: [
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.FILTERS:Filters`,
                link: 'security-operations/filter',
                privileges: ['DEMO_USER'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.FILTERS:Filters`,
                link: 'security-operations/filter/search',
                privileges: ['DEMO_USER'],
            },
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.CORRELATIONS:Correlations`,
                link: 'security-operations/correlation',
                privileges: ['DEMO_USER'],
            },
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.CORRELATIONS:Correlations`,
                link: 'security-operations/correlation/form',
                privileges: ['DEMO_USER'],
            },
            {
                visible: false,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.CORRELATIONS:Correlations`,
                link: 'security-operations/correlation/form/:id',
                privileges: ['DEMO_USER'],
            },
            {
                visible: false,
                text: 'Filter Form',
                link: 'security-operations/filter/form',
                privileges: ['DEMO_USER'],
            },
            {
                visible: false,
                text: 'Filter Form',
                link: 'security-operations/filter/form/:id',
                privileges: ['DEMO_USER'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.CORRELATIONS:Correlations`,
                link: 'security-operations/correlation/search',
                privileges: ['DEMO_USER'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.ENRICHER:Enricher`,
                link: 'security-operations/enricher/search',
                privileges: ['DEMO_USER'],
            },
            {
                visible: true,
                text: $localize`:@@CORE.LEFT_MENU.SECURITY_OPERATIONS.TEMPLATES:Templates`,
                link: 'security-operations/template/search',
                privileges: ['DEMO_USER'],
            },
        ],
    },

    // for auth guard permission check only so menu boolean will be false
    {
        visible: false,
        text: 'Organization Review',
        link: 'org/review',
        privileges: ['ORG_READ'],
    },
    {
        visible: false,
        text: 'Organization Management',
        link: 'admin',
        privileges: ['ORG_READ', 'ORG_ADMIN'],
        childMenus: [
            {
                visible: false,
                text: 'Org Management',
                link: 'admin/org/management/:module',
                modulesPaths: ['admin', 'admin/org'],
                privileges: ['ORG_READ'],
            },
            {
                visible: false,
                text: 'Org Management',
                link: 'admin/org/management/:module/:id',
                modulesPaths: ['admin', 'admin/org'],
                privileges: ['ORG_READ'],
            },
            {
                visible: false,
                text: 'Org Endpoint Provisioning Create',
                link: 'admin/org/management/endpoint-provisioning/create/:orgId',
                modulesPaths: ['admin', 'admin/org'],
                privileges: ['ORG_ADMIN'],
            },
        ],
    },
    {
        visible: false,
        text: 'Finding Search',
        link: 'finding/search',
        privileges: ['FINDING_READ'],
    },
    {
        visible: false,
        text: 'Reaction Search',
        link: 'reaction/search',
        privileges: ['DEMO_USER'],
    },
    {
        visible: false,
        text: 'User Management',
        link: 'admin/user/management',
        privileges: ['USER_CREATE', 'USER_UPDATE', 'USER_READ'],
    },
    {
        visible: false,
        text: 'User Management Details',
        link: 'admin/user/management/details/:orgId',
        modulesPaths: ['admin', 'admin/user/management'],
        privileges: ['USER_CREATE', 'USER_UPDATE', 'USER_READ'],
    },
    {
        visible: false,
        text: 'User Management Details',
        link: 'admin/user/management/details',
        modulesPaths: ['admin', 'admin/user/management'],
        privileges: ['USER_CREATE', 'USER_UPDATE', 'USER_READ'],
    },
    {
        visible: false,
        text: 'User Management Create User',
        link: 'admin/user/management/create/:orgId',
        modulesPaths: ['admin', 'admin/user/management'],
        privileges: ['USER_CREATE'],
    },
    {
        visible: false,
        text: 'User Management Create User',
        link: 'admin/user/management/create',
        modulesPaths: ['admin', 'admin/user/management'],
        privileges: ['USER_CREATE'],
    },
    {
        visible: false,
        text: 'Role Form',
        link: 'admin/role/form',
        privileges: ['ROLE_READ'],
    },
    {
        visible: false,
        text: 'Role Form',
        link: 'admin/role/form/:id',
        privileges: ['ROLE_READ'],
    },
    {
        visible: false,
        text: 'Privilege Form',
        link: 'admin/privilege/form',
        privileges: ['PRIVILEGE_READ'],
    },
    {
        visible: false,
        text: 'Privilege Form',
        link: 'admin/privilege/form/:id',
        privileges: ['PRIVILEGE_READ'],
    },
    {
        visible: false,
        text: 'Finding Form',
        link: 'finding/form/:module',
        privileges: ['FINDING_READ'],
    },
    {
        visible: false,
        text: 'Merchant Scan Results Search',
        link: 'merchant/scan/results/search',
        privileges: ['DEMO_USER'],
    },
    {
        visible: false,
        text: 'Endpoint Configuration Form',
        link: 'endpoint/configuration/form',
        privileges: ['ENDPOINT_USER'],
    },
    {
        visible: false,
        text: 'Endpoint Configuration Form',
        link: 'endpoint/configuration/form/:id',
        privileges: ['ENDPOINT_USER'],
    },
    {
        visible: false,
        text: 'List Form',
        link: 'list/form',
        privileges: ['DEMO_USER'],
    },
    {
        visible: false,
        text: 'List Form',
        link: 'list/form/:id',
        privileges: ['DEMO_USER'],
    },
    {
        visible: false,
        text: 'Enricher Form',
        link: 'security-operations/enricher/form',
        privileges: ['DEMO_USER'],
    },
    {
        visible: false,
        text: 'Enricher Form',
        link: 'security-operations/enricher/form/:id',
        privileges: ['DEMO_USER'],
    },

    {
        visible: false,
        text: 'Task Management',
        link: 'assessment/tasks',
        privileges: ['TASK_READ'],
        childMenus: [
            {
                visible: false,
                text: 'Task Management',
                link: 'assessment/tasks/search/:assessmentId/:id',
                privileges: ['TASK_READ'],
            },
            {
                visible: false,
                text: 'Task Management',
                link: 'assessment/tasks/search/:assessmentId/:id/:module',
                privileges: ['TASK_READ'],
            },
            {
                visible: false,
                text: 'Task Delegation',
                link: 'assessment/tasks/delegation/:assessmentId',
                privileges: ['ASSESSOR_PRIVILEGE', 'ROLE_ASSESSMENT_USER'],
            },
            {
                visible: false,
                text: 'Task Unassign',
                link: 'assessment/tasks/unassign/:assessmentId',
                privileges: ['ASSESSOR_PRIVILEGE', 'ROLE_ASSESSMENT_USER'],
            },
            {
                visible: false,
                text: 'Task Bulk Actions',
                link: 'assessment/tasks/bulk-actions/:assessmentId',
                privileges: ['ASSESSOR_PRIVILEGE', 'ROLE_ASSESSMENT_USER'],
            },
            {
                visible: false,
                text: 'Task Bulk Action summary',
                link: 'assessment/tasks/bulk-action-summary/:assessmentId/:taskBulkActionType',
                privileges: ['ASSESSOR_PRIVILEGE', 'ROLE_ASSESSMENT_USER'],
            },
            {
                visible: false,
                text: 'Task Management',
                link: 'assessment/tasks/management/:assessmentId',
                privileges: ['TASK_READ'],
            },
            {
                visible: false,
                text: 'Task Management',
                link: 'assessment/tasks/management/:assessmentId/:id',
                privileges: ['TASK_READ'],
            },
            {
                visible: false,
                text: 'Task Management',
                link: 'assessment/tasks/management/:assessmentId/:id/:module',
                privileges: ['TASK_READ'],
            },
        ],
    },
    {
        visible: false,
        text: 'Scan Setup Form',
        link: 'scan/setup/form',
        privileges: ['ROLE_SCAN_USER'],
    },
    {
        visible: false,
        text: 'Scan Setup Form',
        link: 'scan/setup/form/:module/:id',
        privileges: ['ROLE_SCAN_USER'],
    },
    {
        visible: false,
        text: 'MSS',
        link: 'mss/managed/connectivity',
        privileges: ['MSS_CONNECTIVITY_VIEW'],
    },
    {
        visible: false,
        text: 'MSS',
        link: 'mss/site/availability',
        privileges: ['MSS_DEMO_USER'],
    },
    {
        visible: true,
        text: 'Event Archive',
        icon: 'history',
        link: 'mss/event-archive',
        privileges: ['EVENTARCHIVE_VIEWER'],
    },
    {
        visible: true,
        text: 'Secure Edge',
        icon: 'lock',
        link: 'mss/secure-edge',
        privileges: ['ROLE_SECUREEDGE_VIEWER'],
    },
    {
        visible: false,
        text: 'MSS',
        link: 'mss/site/detail/:tabName/:id/:siteId',
        privileges: ['MSS_DEMO_USER'],
    },
    {
        visible: false,
        text: 'Endpoint Finding and Device',
        link: 'endpoints/dashboard/:module',
        privileges: ['ENDPOINT_READ'],
    },
    {
        visible: false,
        text: 'Endpoint Device Detail',
        link: 'endpoints/device/detail/:endpointId',
        privileges: ['ENDPOINT_READ'],
    },
    {
        visible: true,
        text: $localize`:@@CORE.LEFT_MENU.TICKETS:Tickets`,
        icon: 'confirmation_number',
        link: 'mss/ticket/search',
        modulesPaths: ['mss', 'mss/ticket'],
        privileges: ['VC_TICKETING_VIEW'],
        childMenus: [
            {
                visible: false,
                link: 'mss/ticket/add/new',
                privileges: ['VC_TICKETING_VIEW'],
            },
            {
                visible: false,
                link: 'mss/ticket/detail',
                privileges: ['VC_TICKETING_VIEW'],
            },
        ],
    },
];
