import { Component, HostBinding, OnInit } from '@angular/core';
import { CommonUtil } from '@libs/vc-core-lib';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import NoDataOptions from 'highcharts/modules/no-data-to-display';

Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);
NoDataOptions(Highcharts);

const { version: appVersion } = require('package.json');

@Component({
    selector: 'vc-core-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    readonly noDataAvailableText = $localize`:@@COMMON_UI.TABLE.NO_DATA_AVAILABLE:No data available`;
    readonly noDataTemplate: string =
        '<div class="vc-chart-empty-container">' +
        '   <div class="vc-chart-no-data">' +
        '       <span class="material-icons vc-chart-empty-icon">bar_chart</span>' +
        `       <span class="vc-chart-no-data-text body-s-1">${this.noDataAvailableText}</span>` +
        '   </div>' +
        '   <div class="vc-chart-empty-background" style="width: 100%; height: 80px"></div>' +
        '</div>';

    @HostBinding('attr.version')
    version = appVersion;

    ngOnInit() {
        CommonUtil.retrieveThemeAndSwitch(false);
        this._initializeHighCharts();
    }

    private _initializeHighCharts() {
        Highcharts.setOptions({
            chart: {
                borderWidth: 2,
                borderRadius: 6,
                backgroundColor: CommonUtil.getRenderedColor('--background'),
                borderColor: CommonUtil.getRenderedColor('--outline'),
                spacingLeft: 20,
                spacingRight: 20,
                style: {
                    fontFamily: 'Poppins',
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        style: {
                            fontSize: '10px',
                            fontWeight: '400',
                            color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                        },
                    },
                },
            },
            title: {
                useHTML: true,
                style: {
                    fontSize: '16px',
                    fontWeight: '400',
                    color: CommonUtil.getRenderedColor('--text-med-emphasis'),
                    align: 'left',
                },
            },
            lang: {
                thousandsSep: ',',
                noData: this.noDataTemplate,
            },
            legend: {
                itemStyle: {
                    fontSize: '12px',
                    fontWeight: '400',
                    height: 16,
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                },
                itemDistance: 24,
                useHTML: true,
            },
            tooltip: {
                backgroundColor: CommonUtil.getRenderedColor('--secondary-800'),
                borderColor: CommonUtil.getRenderedColor('--outline'),
                padding: 6,
                style: {
                    color: CommonUtil.getRenderedColor('--primary-50'),
                    fontSize: '12px',
                },
                useHTML: true,
            },
            navigation: {
                menuStyle: {
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    background: CommonUtil.getRenderedColor('--background'),
                    borderColor: CommonUtil.getRenderedColor('--outline'),
                },
                menuItemStyle: {
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    background: CommonUtil.getRenderedColor('--background'),
                },
                menuItemHoverStyle: {
                    color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    background: CommonUtil.getRenderedColor('--primary-50'),
                },
            },
            yAxis: {
                labels: {
                    style: {
                        fontSize: '10px',
                        color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    },
                },
                title: {
                    style: {
                        fontSize: '10px',
                        color: CommonUtil.getRenderedColor('--text-medium-emphasis'),
                    },
                },
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: '10px',
                        color: CommonUtil.getRenderedColor('--text-high-emphasis'),
                    },
                },
                title: {
                    style: {
                        fontSize: '10px',
                        color: CommonUtil.getRenderedColor('--text-medium-emphasis'),
                    },
                },
            },
            noData: {
                useHTML: true,
            },
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
        });
    }
}
